class Fancybox {
    constructor(selector) {
        this.selector = selector;
        this.init();
    }
    init() {
        $(document).ready(() => {
            $(this.selector + " a").fancybox({
            });
        });
    }
}
const fancybox = new Fancybox("[data-custom-fancybox-and-slick-slider]");
