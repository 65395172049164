class ChatManager {
    constructor() {
        this.init();
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    init() {
        document.addEventListener('DOMContentLoaded', this.checkWidthAndRemoveClass.bind(this));
        const buttonBackTabElements = document.querySelectorAll('[data-chat-button-back]');
        buttonBackTabElements.forEach(elem => {
            elem.addEventListener('click', () => {
                this.checkWidthAndRemoveClass();
            });
        });
        this.handleNavChatClick();
    }

    checkWidthAndRemoveClass() {
        let contentTabElement = document.querySelector('.b-content-tab_active.b-tab_disactive-lg');
        let navTabElement = document.querySelector('.b-tab-active-nav-item.b-tab_disactive-lg');
        let tabNavChatElem = document.querySelectorAll('[data-tab-nav-chat]');
        if (window.innerWidth < 1080 && contentTabElement && navTabElement) {
            contentTabElement.classList.remove('b-content-tab_active');
            navTabElement.classList.remove('b-tab-active-nav-item');
        }
        if (tabNavChatElem) {
            tabNavChatElem.forEach(elem2 => {
                elem2.style.display = 'block';
            });
        }
    }

    handleResize() {
        if (window.innerWidth === 1080) {
            this.deleteNavChat();
        }
    }

    handleNavChatClick() {
        let tabNavChatElem = document.querySelectorAll('[data-tab-nav-chat]');
        tabNavChatElem.forEach(elem1 => {
            elem1.addEventListener('click', (event) => {
                tabNavChatElem.forEach(elem2 => {
                    if (elem2 !== elem1 && window.innerWidth < 1080) {
                        elem2.style.display = 'none';
                    }
                });
            });
        });
    }

    deleteNavChat() {
        let tabNavChatElem = document.querySelectorAll('[data-tab-nav-chat]');
        tabNavChatElem.forEach(elem1 => {
            elem1.addEventListener('click', (event) => {
                tabNavChatElem.forEach(elem2 => {
                    if (elem2 !== elem1 && window.innerWidth < 1080) {
                        elem2.style.display = 'none';
                    }
                });
            });
        });
    }

}

const tabManager = new ChatManager();