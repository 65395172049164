class SlickSlider {
    constructor(selector) {
        this.selector = selector;
        this.init();
    }

    init() {
        $(document).ready(() => {
            $(this.selector).slick({
                infinite: true,
                slidesToShow: 1,
                prevArrow: '<button type="button" class="slick-prev">Previous</button>',
                nextArrow: '<button type="button" class="slick-next">Next</button>',
            });
        });
    }
}


const slickSlider = new SlickSlider("[data-custom-fancybox-and-slick-slider]");