const blocks = document.querySelectorAll('.b-accordion')

const close = accordion => {
    accordion.classList.remove('active-accordion')
    const body = accordion.querySelector('.b-accordion__body')
    body.style.maxHeight = '0px'
}

const open = accordion => {
    accordion.classList.add('active-accordion')
    const body = accordion.querySelector('.b-accordion__body')
    body.style.maxHeight = body.scrollHeight + 'px'
}

const resize = accordion => {
    const body = accordion.querySelector('.b-accordion__body')
    body.style.maxHeight = body.scrollHeight + 'px'
}


blocks.forEach(block => {
    const accordions = block.querySelectorAll('.b-accordion__item')

    accordions.forEach(accordion => {
        const head = accordion.querySelector('.b-accordion__head')
        accordion.classList.contains('active-accordion') && open(accordion)

        head.addEventListener('click', () => {
            accordion.classList.contains('active-accordion') ? close(accordion) : open(accordion)
            accordions.forEach(item => (item !== accordion) && close(item))
        })

        window.addEventListener('resize', () => {
            accordion.classList.contains('active-accordion') && resize(accordion)
        })
    })
})