document.addEventListener('DOMContentLoaded', function() {
    const eyaFields = document.querySelectorAll('[data-field-eya]');
    eyaFields.forEach(function(field) {
        const input = field.querySelector('.b-field__input');
        const eyeIcon = field.querySelector('.b-field__eye');
        if (input && eyeIcon && field.contains(input) && field.contains(eyeIcon)) {
            let isPasswordVisible = false;
            eyeIcon.addEventListener('click', function() {
                isPasswordVisible = !isPasswordVisible;
                input.type = isPasswordVisible ? 'text' : 'password';
                eyeIcon.classList.toggle('active', isPasswordVisible);
            });
        }
    });
});