function scrollToBottom() {
    let cardCenterElements = document.querySelectorAll('.b-card-xxl-primary__center');
    cardCenterElements.forEach((cardCenterElement) => {
        cardCenterElement.scrollTop = cardCenterElement.scrollHeight;
    });
}

document.addEventListener('DOMContentLoaded', function () {
    scrollToBottom()
});
