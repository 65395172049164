class CitySelector {
    constructor() {
        this.countryListSelector = '[data-country-list]';
        this.countryTargetSelector = '[data-country-target]';
        this.locationCountriesElement = document.querySelector('.b-modal-location__countries');
        this.locationCitiesElement = document.querySelector('.b-modal-location__cities');

        this.init();
    }

    init() {
        let countryList = document.querySelector(this.countryListSelector);
        if (countryList) {
            let countryButtons = document.querySelectorAll(this.countryTargetSelector);
            countryButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    let selectedCountryId = e.target.getAttribute('data-country-target');
                    let countryInput = document.querySelector('[data-country-input]');
                    countryInput.value = selectedCountryId;
                    this.locationCountriesElement.classList.add('b-modal-location__countries_display-none');
                    this.locationCitiesElement.classList.remove('b-modal-location__countries_display-none')
                });
            });

            document.querySelectorAll('[data-city-name]').forEach((cityBtn) => {
                cityBtn.addEventListener('click', (e) => {
                    this.deleteAllElements('data-city-name', 'b-modal-location__city_active')
                    cityBtn.classList.add('b-modal-location__city_active')
                    let cityInput = document.querySelector('[data-city-input]');
                    cityInput.value = e.target.getAttribute('data-city-name');
                    this.locationCountriesElement.classList.remove('b-modal-location__countries_display-none');
                    this.locationCitiesElement.classList.add('b-modal-location__countries_display-none')
                    MicroModal.close('modal-location');
                });
            });
        }
    }

    deleteAllElements(elements, className) {
        console.log(elements)
        document.querySelectorAll('[data-city-name]').forEach((cityBtn) => {
            cityBtn.classList.remove(className);
        })
    }
}


new

CitySelector();