class CustomSubmitInput {
    constructor() {
        this.init();
    }

    init() {
        document.addEventListener('DOMContentLoaded', this.handleDOMLoaded.bind(this));
    }

    handleDOMLoaded() {
        const submitInputs = document.querySelectorAll('[data-submit-input]');

        submitInputs.forEach(submitInput => {
            const form = submitInput.closest('form');
            if (form) {
                const realSubmitInput = form.querySelector('.b-submit-input__button-submit');
                if (realSubmitInput) {
                    submitInput.addEventListener('click', this.handleSubmitClick.bind(this, realSubmitInput));
                }
            }
        });
    }

    handleSubmitClick(realSubmitInput, event) {
        realSubmitInput.click();
        event.preventDefault();
    }
}

new CustomSubmitInput();