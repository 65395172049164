MicroModal.init({
    openClass: 'b-is-open',
    disableScroll: true,
});

function closeModalOnResize() {
    const modal = document.querySelector('#mobile-menu');
    if (!modal) return;

    if (window.innerWidth >= 780 && modal.classList.contains('b-is-open')) {
        MicroModal.close('mobile-menu');
    }
}

window.addEventListener('resize', closeModalOnResize);


