class Dropdown {
    constructor(btn, dropdown, btnId) {
        this.btn = btn;
        this.dropdown = dropdown;
        this.btnId = btnId;
        this.isHidden = true;
        this.init();
    }

    init() {
        this.setEvents();
    }

    setEvents() {
        if (this.btn) {
            this.btn.addEventListener('mousedown', (e) => {
                e.preventDefault();
                this.isHidden ? this.open() : this.close();
            });
            document.addEventListener('click', this.clickOutsideHandler.bind(this));
        }
    }

    open() {
        this.dropdown.style.display = 'flex';
        this.isHidden = false;
    }

    close() {
        this.dropdown.style.display = 'none';
        this.isHidden = true;
    }

    clickOutsideHandler(e) {
        const isOutside = !e.target.closest(`#${this.btnId}`) && e.target !== this.btn;
        if (isOutside) {
            this.close();
        }
    }
}

new Dropdown(document.getElementById('header-btn'), document.getElementById('header-dropdown'), 'header-btn');
new Dropdown(document.getElementById('header-secondary-btn'), document.getElementById('header-secondary-dropdown'), 'header-secondary-btn');