function updateIconOnSelectChange() {
    let select = document.getElementById('select-field');
    let icon = document.getElementById('logo-field');
    if (select && icon) {
        select.addEventListener('change', (e) => {
            let optionElement = e.target.querySelector('option[value="' + e.target.value + '"]');
            let iconUrl = optionElement.getAttribute('data-icon');
            icon.setAttribute('src', iconUrl);
        });
    }

}

updateIconOnSelectChange();