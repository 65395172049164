class Tab {
    constructor(navElement, group, targetElements, targetElementSelector) {
        this.navElement = navElement
        this.group = group
        this.targetElements = targetElements
        this.targetElementSelector = targetElementSelector
    }
}

class Tabs {
    constructor(config) {
        this.defaultConfig = {
            navActiveClassName: 'b-tab-active-nav-item',
            contentActiveClassName: 'b-content-tab_active',
            navGroupAttributeName: 'data-tab-nav-group',
            targetSelector: '[data-tab-target]',
            targetAttributeName: 'data-tab-target',
            contentGroupAttributeName: 'data-tab-content-group',
            contentGroupSelector: '[data-tab-content-group]',
        }
        this.config = this.mergeConfigs(this.defaultConfig, config);
        this.tabs = this.build()
        this.init()
    }

    init() {
        this.tabs.forEach((tab) => {
            if (this.shouldActivateTabByDefault(tab)) {
                this.activate(tab)
            }
            tab.navElement.addEventListener('click', (e) => {
                this.activate(tab)
            })
        })
    }

    activate(tab) {
        if (this.tabHasParent(tab)) {
            this.activate(this.getParentTab(tab))
        }
        this.deactivateNavGroup(tab.group)
        this.activateNav(tab.navElement)
        this.deactivateContentGroup(tab.group)
        this.activateTargetElements(tab.targetElements)
        //calling a third party function
        scrollToBottom()
    }

    deactivateNavGroup(group) {
        let groupNavElements = document.querySelectorAll(`[${this.config.navGroupAttributeName}=${group}]`)
        groupNavElements.forEach((element) => {
            element.classList.remove(this.config.navActiveClassName)
        })
    }

    activateNav(navElement) {
        navElement.classList.add(this.config.navActiveClassName)
    }

    deactivateContentGroup(group) {
        let groupContentElements = document.querySelectorAll(`[${this.config.contentGroupAttributeName}=${group}]`)
        groupContentElements.forEach((element) => {
            element.classList.remove(this.config.contentActiveClassName)
        })
    }

    activateTargetElements(targetElements) {
        targetElements.forEach((targetElement) => {
            targetElement.classList.add(this.config.contentActiveClassName)
        })
    }

    // Prepare tabs array to work with every tab. - navElement, group, targetElements
    build() {
        let navElements = this.getNavElements()
        let tabs = []
        navElements.forEach((navElement) => {
            let targetElementSelector = navElement.getAttribute(this.config.targetAttributeName)
            if (!targetElementSelector) {
                throw Error(`Target element is not defined for ${navElement}`)
            }
            let group = navElement.getAttribute(this.config.navGroupAttributeName)
            if (!group) {
                throw Error(`Group is not defined for ${navElement}`)
            }
            tabs.push(new Tab(
                navElement,
                group,
                document.querySelectorAll(targetElementSelector),
                targetElementSelector
            ))
        })
        return tabs
    }

    getNavElements() {
        return document.querySelectorAll(this.config.targetSelector)
    }

    shouldActivateTabByDefault(tab) {
        return tab.targetElementSelector === window.location.hash
    }

    tabHasParent(tab) {
        let parent = tab.navElement.closest(this.config.contentGroupSelector)
        return !!parent
    }

    getParentTab(tab) {
        let parentTabElement = tab.navElement.closest(this.config.contentGroupSelector)
        return this.tabs.find((currentTab) => {
            return Array.prototype.find.call(currentTab.targetElements, (targetElement) => {
                return targetElement === parentTabElement
            })
        })
    }
    mergeConfigs(defaultConfig, config ) {
        let mergedConfig  = {};

        for (let a in defaultConfig) {
            if (defaultConfig.hasOwnProperty(a)) {
                mergedConfig [a] = defaultConfig[a];
            }
        }

        for (let i in config ) {
            if (config .hasOwnProperty(i)) {
                mergedConfig [i] = config [i];
            }
        }
        return mergedConfig;
    }
}

document.addEventListener('DOMContentLoaded', (e) => {
    new Tabs()
})

