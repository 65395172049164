class ElementsHandler {
    constructor(elements1, elements2, addedElement) {
        this.elements1 = elements1;
        this.elements2 = elements2;
        this.addedElement = addedElement;
        this.init();
    }

    init() {
        let elements1 = document.querySelectorAll('.' + this.elements1);
        let elements2 = document.querySelectorAll('.' + this.elements2);

        elements1.forEach((element) => {
            element.addEventListener('click', () => {
                this.handleImageClick(element);
            });
        });

        elements2.forEach((element) => {
            element.addEventListener('click', () => {
                this.handleImageClick(element);
            });
        });
    }

    handleImageClick(element) {
        element.classList.add(this.addedElement);
        let siblingImgElement = this.getSiblingImageElement(element);
        if (siblingImgElement) {
            siblingImgElement.classList.remove(this.addedElement);
        }
    }

    getSiblingImageElement(element) {
        if (element.classList.contains(this.elements1)) {
            return element.nextElementSibling;
        } else if (element.classList.contains(this.elements2)) {
            return element.previousElementSibling;
        }
        return null;
    }
}

new ElementsHandler('b-modal-store__button-1', 'b-modal-store__button-2', 'b_none');
new ElementsHandler('b-label__img-mark', 'b-label__img-blue', 'b_none');